<script>
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { addUnit, setIframeDimensions, objToQueryParams } from '@shared/services/EmbedUtils';

export default {
  name: 'Embeds',
  head: {
    title: 'Intégrations externes',
  },
  data: () => ({
    embed: {
      buyable: '',
      buyable_type: '',
      type: 'BUTTON',
      text: '',
      sizeType: 'px',
      params: {
        style: {
          width: '',
          height: '',
        },
      },
    },
    embedSrc: null,
    isFetching: false,
    iframeIsLoading: false,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    trainings() {
      return this.$store.state.trainings.list;
    },
    trainingsData() {
      if (this.trainings && this.trainings.data) {
        return this.trainings.data.filter((one) => one.status !== 'DRAFT');
      }

      return null;
    },
    bundles() {
      return this.$store.state.bundles.list;
    },
    bundlesData() {
      if (this.bundles && this.bundles.data) {
        return this.bundles.data.filter((bundle) => bundle.status !== 'DRAFT');
      }

      return null;
    },
    embedUrl() {
      const { protocol } = window.location;
      const { subdomain } = this.authStore;
      const { rootHost } = this.$env;
      const { slug } = this.embed.buyable;
      const type = this.embed.buyable_type === this.$constants.BUYABLE_TYPE.BUNDLE
        ? 'packs' : 'formations';

      return `${protocol}//${subdomain}.${rootHost}/${type}/${slug}`;
    },
    embedCode() {
      if (this.embed.type && this.embed.buyable) {
        const { text, sizeType } = this.embed;
        let params = cloneDeep(this.embed.params);
        params.style.width = addUnit(params.style.width && `${params.style.width}${sizeType}`);
        params.style.height = addUnit(params.style.height);
        params = JSON.stringify(params);

        /* eslint-disable no-useless-escape */
        return `
          <script src="${this.$env.apiDomain}/embeds/embed.js" async="async"><\/script><a href="${this.embedUrl}" data-teachizy="${this.embed.type}" data-teachizy-params='${params}'>${text || this.embed.buyable.name}</a>
        `.trim();
      }

      return null;
    },
  },
  watch: {
    embed: {
      deep: true,
      handler: debounce(function() {
        if (this.embed.type && this.embed.buyable) {
          const type = this.embed.type.toLowerCase();
          const { text, sizeType } = this.embed;

          if (type === 'link') {
            this.embed.params.style.width = '';
          }

          if (['link', 'form'].includes(type)) {
            this.embed.params.style.height = '';
          }

          const params = cloneDeep(this.embed.params);
          let url = `${this.embedUrl}/embed/${type}?`;

          text && (url += `t=${encodeURIComponent(text)}&`);
          params.style.width = addUnit(params.style.width && `${params.style.width}${sizeType}`);
          params.style.height = addUnit(params.style.height);
          url += objToQueryParams(params);

          this.embedSrc = url;
          return;
        }

        this.embedSrc = null;
      }, 500),
    },
    embedSrc() {
      const { iframe } = this.$refs;

      if (iframe) {
        iframe.style = '';
        iframe.width = '100%';
        iframe.height = '0';
      }

      this.embed.type !== 'LINK' && (this.iframeIsLoading = true);
    },
  },
  methods: {
    reloadPreview() {
      const old = this.embedSrc;
      this.embedSrc = null;
      this.$nextTick(() => (this.embedSrc = old));
    },
    onSelect(value) {
      this.embed.buyable = value.buyable;
      this.embed.buyable_type = value.type;
    },
  },
  created() {
    this.isFetching = true;
    Promise.all([
      this.$store.dispatch('trainings/fetch')
        .then(() => {
          const { formation: uuid } = this.$route.query;

          if (uuid) {
            this.embed.buyable = this.trainings.data.find((one) => one.uuid === uuid);
            this.embed.buyable_type = this.$constants.BUYABLE_TYPE.TRAINING;
          }
        }),
      this.$store.dispatch('bundles/fetch')
        .then(() => {
          const { pack: uuid } = this.$route.query;

          if (uuid) {
            this.embed.buyable = this.bundles.data.find((one) => one.uuid === uuid);
            this.embed.buyable_type = this.$constants.BUYABLE_TYPE.BUNDLE;
          }
        }),
    ])
      .finally(() => (this.isFetching = false));

    window.addEventListener('message', (event) => {
      const { iframe } = this.$refs;

      if (!iframe) {
        return;
      }

      if (event.source === iframe.contentWindow) {
        const { data } = event;

        if (data.name === 'dimensions') {
          setIframeDimensions(iframe, data);
          this.iframeIsLoading = false;
        }
      }
    });
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Intégrez vos formations et vos packs à un site externe
      </h2>
      <p>
        Vous avez un site existant ?
        Que vous utilisiez Wix, Wordpress, Drupal, Ionos, Leadpage ou Instapage...
      </p>
      <p>
        Nous avons développé cette fonctionnalité d'intégration externe
        pour vous permettre de <strong>faciliter les inscriptions à vos formations</strong>
        depuis n'importe quel site web : sous forme de <strong>liens</strong>,
        de <strong>boutons</strong> ou de <strong>cartes</strong>.
      </p>
      <p>
        Vous pouvez suivre notre tutoriel sur le sujet en
        <a
          class="has-text-weight-bold"
          href="https://youtu.be/Xj9zDnpwG4M"
          target="_blank"
          rel="noopener noreferrer">
          cliquant ici
        </a>
      </p>
      <p v-if="authStore.capabilities.nb_discount_code < 0" class="box is-warning is-flex">
        <b-icon class="mr-3" icon="gem" />
        <span>
          Intégrez vos formations et vos packs sur autant de sites externes que vous voulez grâce à votre
          <span v-t="`packs.${authStore.plan}`" />
        </span>
      </p>
    </div>

    <div class="column is-8">
      <b-skeleton v-if="isFetching" height="100" />
      <form v-else class="box">
        <p v-if="(!trainingsData || !trainingsData.length) && (!bundlesData || !bundlesData.length)">
          Vous n'avez aucun produit publié à intégrer pour le moment.
        </p>
        <b-field v-else label="Quel produit souhaitez-vous exporter ?">
          <b-select :value="{ buyable: embed.buyable, type: embed.buyable_type }" @input="onSelect" expanded>
            <option value="">Choisissez...</option>
            <template v-if="trainingsData.length">
              <option disabled>-- Formations --</option>
              <option v-for="training in trainingsData" :key="training.id" :value="{ buyable: training, type: $constants.BUYABLE_TYPE.TRAINING }">
                {{ training.name }}
              </option>
            </template>
            <template v-if="bundlesData.length">
              <option disabled>-- Packs --</option>
              <option v-for="bundle in bundlesData" :key="bundle.id" :value="{ buyable: bundle, type: $constants.BUYABLE_TYPE.BUNDLE }">
                {{ bundle.name }}
              </option>
            </template>
          </b-select>
        </b-field>
        <template v-if="embed.buyable">
          <b-field label="Quel type de module ?">
            <b-select v-model="embed.type" expanded>
              <option value="BUTTON">Bouton</option>
              <option value="LINK">Lien</option>
              <template v-if="embed.buyable_type === $constants.BUYABLE_TYPE.TRAINING">
                <option value="CARD">Carte</option>
                <option value="FORM">Formulaire</option>
              </template>
            </b-select>
          </b-field>

          <b-field label="Personnalisez le texte si besoin">
            <b-input v-model="embed.text" />
          </b-field>
          <template v-if="embed.type !== 'LINK'">
            <b-field label="Personnalisez la largeur">
              <b-input type="number" v-model.number="embed.params.style.width" expanded />
              <b-select v-model="embed.sizeType">
                <option value="px">Pixels (px)</option>
                <option value="%">Pourcentage (%)</option>
              </b-select>
            </b-field>
            <b-field
              v-show="embed.type !== 'FORM'"
              label="Personnalisez la hauteur minimum (en pixel)">
              <b-input type="number" v-model.number="embed.params.style.height" expanded />
            </b-field>
          </template>
        </template>
        <b-field
          v-if="embed.buyable"
          label="Copiez / collez la ligne de code HTML ci-dessous dans le code de votre site web">
          <b-input type="textarea" :value="embedCode" expanded disabled />
          <b-button
            class="ml-5"
            v-clipboard:copy="embedCode"
            v-clipboard:success="() => this.$copySuccess()"
            v-clipboard:error="() => this.$copyError()"
          >
            Copier
          </b-button>
        </b-field>
        <b-field v-if="embed.buyable" label="Aperçu du module :" :addons="false">
          <div class="p-3 bradius-4 border-dashed">
            <iframe
              v-if="embed.type !== 'LINK'"
              ref="iframe"
              style="max-width:100%;"
              :style="{opacity: iframeIsLoading ? 0 : 1}"
              width="100%"
              height="0"
              :src="embedSrc"
            />
            <a v-else class="tdecoration-underline" href="#" :style="{color: 'initial'}" @click.prevent>
              {{ embed.text || embed.buyable.name }}
            </a>
            <div v-show="iframeIsLoading" class="has-text-centered">
              <div class="is-inline-block loader is-primary is-size-1" />
            </div>
          </div>
          <p v-show="!iframeIsLoading" class="help">
            <a href="#" @click.prevent="reloadPreview">
              Recharger l'aperçu
            </a>
          </p>
        </b-field>
      </form>
    </div>
  </div>
</template>
